export const GRID_CONFIG = [
  [0,0],
  [-1,-.5],
  [0,-1],
  [1,-.5],
  [1,.5],
  [0,1],
  [-1,.5],
  [-2,0],
  [-2,-1],
  [-1,-1.5],
  [0,-2],
  [1,-1.5],
  [2, -1],
  [2,0],
  [2,1],
  [1,1.5],
  [0,2],
  [-1, 1.5],
  [-2, 1],
  [-3,-.5],
  [-3, -1.5],
  [-2, -2],
  [2, -2],
  [3, -1.5],
  [3,-.5],
  [3, .5],
  [3, 1.5],
  [2, 2],
  [-2, 2],
  [-3, 1.5],
  [-3,.5],
  [-3, -2.5],
  [-1, -2.5],
  [1, -2.5],
  [3, -2.5],
  [3, 2.5],
  [1, 2.5],
  [-1, 2.5],
  [-3, 2.5],
  [-2, -3],
  [0, -3],
  [2, -3],
  [2, 3],
  [0, 3],
  [-2, 3],
  [-3, -3.5],
  [-1, -3.5],
  [1, -3.5],
  [3, -3.5],
  [3, 3.5],
  [1, 3.5],
  [-1, 3.5],
  [-3, 3.5],
  [-2, -4],
  [0, -4],
  [2, -4],
  [2, 4],
  [0, 4],
  [-2, 4]
]